// export const URL = "http://localhost:5002/api/";
export const URL = "https://api.safeler.com/bcms/api/";
// export const URL = "https://bb-bcms.herokuapp.com/api/";
// https://api.safeler.com/bcms
// export const URL = "https://bbbcms-api.herokuapp.com/api/";
// export const URL = process.env.REACT_APP_API_ENDPOINT;

export const CUSTOMER_FETCH_REQUEST = "CUSTOMER_FETCH_REQUEST";
export const CUSTOMER_FETCH_SUCCESS = "CUSTOMER_FETCH_SUCCESS";
export const CUSTOMER_FETCH_FAIL = "CUSTOMER_FETCH_FAIL";

export const REMITTANCE_CLIENT_REQUEST = "REMITTANCE_CLIENT_REQUEST";
export const REMITTANCE_CLIENT_REQUEST_SUCCESS =
	"REMITTANCE_CLIENT_REQUEST_SUCCESS";
export const REMITTANCE_CLIENT_REQUEST_FAIL = "REMITTANCE_CLIENT_REQUEST_FAIL";

export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_REQUEST_SUCCESS = "AUTH_REQUEST_SUCCESS";
export const AUTH_REQUEST_FAIL = "AUTH_REQUEST_FAIL";
export const LOGOUT_USER = "LOGOUT_USER";

// join
export const JOIN_REQUEST = "JOIN_REQUEST";
export const JOIN_REQUEST_SUCCESS = "JOIN_REQUEST_SUCCESS";
export const JOIN_REQUEST_FAIL = "JOIN_REQUEST_FAIL";

// Profile

export const PROFILE_REQUEST = "PROFILE_REQUEST";
export const PROFILE_REQUEST_SUCCESS = "PROFILE_REQUEST_SUCCESS";
export const PROFILE_REQUEST_FAIL = "PROFILE_REQUEST_FAIL";

export const VERIFIED = "VERIFIED";
