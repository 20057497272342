import {
    AUTH_REQUEST,
    AUTH_REQUEST_SUCCESS,
    AUTH_REQUEST_FAIL,
    URL,
    LOGOUT_USER,
    JOIN_REQUEST,
    JOIN_REQUEST_SUCCESS,
    JOIN_REQUEST_FAIL
} from "./types"

import axios from "axios"

const formatError = (error) => {
    // Format the error message into a readable format
    let errorMessage = 'An unknown error occurred.';
    if (error.response) {
        errorMessage = `Request failed with status code: ${error.response.status}`;
    } else if (error.request) {
        errorMessage = 'The server did not respond.';
    } else {
        errorMessage = error.message;
    }
    return errorMessage;
};

export const authenticate = (email, password) => async (dispatch) => {
    let user = {
        email,
        password
    }

    console.log(user);
    try {
        dispatch({ type: AUTH_REQUEST })
        const { data } = await axios.post(`${URL}user/login`, user)
        // set token localstorage
        localStorage.setItem('token', JSON.stringify(data.token))
        // dispatch success
        dispatch({ type: AUTH_REQUEST_SUCCESS, payload: data.user })

    } catch (error) {
        console.log(formatError(error));
        dispatch({ type: AUTH_REQUEST_FAIL, payload: formatError(error) })
    }
}

export const join = (name, phone, email, address, company, password) => async (dispatch) => {
    let user = {
        name,
        phone,
        email,
        address,
        company,
        password
    }

    try {
        dispatch({ type: JOIN_REQUEST })
        const { data } = await axios.post(`${URL}user/register`, user)
        if (data.user) {
            // set token localstorage
            localStorage.setItem('token', JSON.stringify(data.token))
            // dispatch success
            dispatch({ type: JOIN_REQUEST_SUCCESS, payload: data.user })
        } else {
            dispatch({ type: JOIN_REQUEST_FAIL, payload: data.error })
        }

    } catch (error) {
        dispatch({ type: JOIN_REQUEST_FAIL, payload: "something wrong !" })
    }


}

export const checkAuth = () => async (dispatch) => {
    let token = JSON.parse(localStorage.getItem('token'))
    if (!token) {
        return false
    }
    let config = {
        headers: {
            'Content-type': 'aplication/json',
            'x-auth-token': token
        }
    }

    try {
        dispatch({ type: AUTH_REQUEST })
        const { data } = await axios.get(`${URL}user`, config)
        if (data.authenticated) {
            dispatch({ type: AUTH_REQUEST_SUCCESS, payload: data.user })
        } else {
            dispatch({ type: AUTH_REQUEST_FAIL, payload: "invalid token" })
        }
    } catch (error) {
        dispatch({ type: AUTH_REQUEST_FAIL, payload: "server response fail" })
    }

}

export const logout = () => (dispatch) => {
    localStorage.removeItem('token')
    dispatch({ type: LOGOUT_USER })
}